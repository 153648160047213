import { get, getForReport,getForReportCatalog, post } from "../lib/request";


export const fetchCatalogStatistics = (
  skip,
  limit,
  storeIdValue,
  search
) => {

  let URL = `/stats/catalog?skip=${skip}&limit=${limit}&storeId=${storeIdValue}&search=${search}`
  // let URL = storeIdValue != 0 ? `/stats/catalog?skip=${skip}&limit=${limit}&store_id=${storeIdValue}` : `/stats/catalog?skip=${skip}&limit=${limit}`
  // console.log("URL" ,URL)

  // if (storeIdValue){
  //   let URL = `/stats/catalog?skip=${skip}&limit=${limit}&store_id=${storeIdValue}`;
  //   return getForReport(URL);
  // }
  // else{
  //   let URL = `/stats/catalog?skip=${skip}&limit=${limit}`
  //   console.log("else URL === ",URL)
  //   return getForReport(URL);
  // }
  return getForReportCatalog(URL);

};


export const fetchCatalogStatisticsStore = (
  skip,
  limit
) => {
  let URL = `/stats/store?skip=${skip}&limit=${limit}`;
  return getForReport(URL);
};