// Dependency Imports
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// Custom Imports
import "../../static/scss/App.scss";
import Header from "../../components/header/header";
//icons
import EmailIcon from "@material-ui/icons/Email";
import Product from "@material-ui/icons/ShoppingBasketOutlined";
import CategoryIcon from "@material-ui/icons/ViewCarousel";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import SearchIcon from "@material-ui/icons/Search";
import StarHalfIcon from "@material-ui/icons/StarHalf";
import FavoriteIcon from "@material-ui/icons/Favorite";
import PollIcon from "@material-ui/icons/Poll";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
//helper function
import {
  Route,
  withRouter,
  // BrowserRouter as Router,
  // Redirect,
} from "react-router-dom";

//nested routes
import ProductSearchedLogs from "./ProductSearchedLogs/ProductSearchedLogs";
import CategoryLogs from "./CategoryLogs/CategoryLogs";
import SubCategoryLogs from "./subcategoryLogs/subcategoryLogs";
import SubSubCategoryLogs from "./SubSubCategoryLogs/SubSubCategoryLogs";
import ProductClickLogs from "./ProductClickLogs/ProductClickLogs";
import ReviewLogs from "./ReviewLogs/ReviewLogs";
import RatingLogs from "./RatingLogs/RatingLogs";
import FavouriteProductLogs from "./FavouriteProductLogs/FavouriteProductLogs";
import Overview from "./Overview/Overview";
import LoginLogs from "./LoginLogs/LoginLogs";
import EmailLogs from "./EmailLogs/EmailLogs";
import SellerRatingLogs from "./SellerRatingLogs/SellerRatingLogs";
import SellerReviewLogs from "./SellerReviewLogs/SellerReviewLogs";
import SalesPage from "./Sales/ReportSub";
import TopNProductLogs from "./TopSellingProducts/ReportSub";
import TotalOrders from "./TotalOrders/ReportSub";
import SessionAnalytics from "./SessionAnalytics";
import DauAndMau from "./DauAndMau";
import ProductPerformance from "./ProductPerformance";
import SalesPerformance from "./SalesPerformance";
import Funnel from "./FunnelChart";
import DeviceInstallationReport from "./DeviceInstallationReport";
import DeviceSessionReport from "./DeviceSessionReport";
import DeviceActivityBySession from "./DeviceActivityBySession";
import BookingPaymentActivity from "./BookingPaymentActivity";
import WishList from "./WishList";
import Cart from "./TopCart";
import HeatMap from "./HeatMap";
import SellerStatistics from './SellerStatistics'
import CatalogStatistics from "./CatalogStatistics";
/**
 * @author Jai
 * @taskAsignedBy Rohit Sir
 * @date 06 April, 2021
 * @Changes I commented four charts from the dashboard {1:Dau And Mau, 2:Device Installation Report 3:Device Session Report, 4:Device Activity By Session}
 */
class LandingIndex extends React.Component {
  state = {
    cartLogs: " ",
    ProductRecentView: " ",
    FavProduct: " ",
    sidePanel: [
      {
        name: "Dashboard",
        url: `${this.props.match.url}/overview`,
        icon: PollIcon,
        component: Overview,
      },
      {
        name: "Sales Over Time",
        url: `${this.props.match.url}/sales-report`,
        icon: PollIcon,
        component: SalesPage,
      },
      {
        name: "DAU/MAU",
        url: `${this.props.match.url}/DAU-MAU-report`,
        icon: PollIcon,
        component: DauAndMau,
      },
      {
        name: "Orders Report",
        url: `${this.props.match.url}/orders-report`,
        icon: PollIcon,
        component: TotalOrders,
      },
      {
        name: "Product Searched Logs",
        url: `${this.props.match.url}/product-searched-logs`,
        icon: SearchIcon,
        component: ProductSearchedLogs,
      },
      {
        name: "User Session",
        url: `${this.props.match.url}/user-session`,
        icon: PollIcon,
        component: SessionAnalytics,
      },
      {
        name: "Top-N Product Logs",
        url: `${this.props.match.url}/top-n-product-logs`,
        icon: VpnKeyIcon,
        component: TopNProductLogs,
      },
      {
        name: "Login Logs",
        url: `${this.props.match.url}/login-logs`,
        icon: VpnKeyIcon,
        component: LoginLogs,
      },
      {
        name: "Email Logs",
        url: `${this.props.match.url}/email-logs`,
        icon: EmailIcon,
        component: EmailLogs,
      },

      {
        name: "Product Review logs",
        url: `${this.props.match.url}/product-review-logs`,
        icon: LoyaltyIcon,
        component: ReviewLogs,
      },
      {
        name: "Product Rating logs",
        url: `${this.props.match.url}/product-rating-logs`,
        icon: StarHalfIcon,
        component: RatingLogs,
      },
      {
        name: "Seller Review logs",
        url: `${this.props.match.url}/seller-review-logs`,
        icon: LoyaltyIcon,
        component: SellerReviewLogs,
      },

      {
        name: "Seller Rating logs",
        url: `${this.props.match.url}/seller-rating-logs`,
        icon: StarHalfIcon,
        component: SellerRatingLogs,
      },
      {
        name: "Category Logs",
        url: `${this.props.match.url}/category-logs`,
        icon: CategoryIcon,
        component: CategoryLogs,
      },
      {
        name: "Sub Category Logs",
        url: `${this.props.match.url}/subcategory-logs`,
        icon: CategoryIcon,
        component: SubCategoryLogs,
      },

      {
        name: "Sub Sub Category Logs",
        url: `${this.props.match.url}/subsubcategory-logs`,
        icon: CategoryIcon,
        component: SubSubCategoryLogs,
      },
      {
        name: "Product Click Logs",
        url: `${this.props.match.url}/product-click-logs`,
        icon: Product,
        component: ProductClickLogs,
      },
      {
        name: "Favoutrite Product Logs",
        url: `${this.props.match.url}/favourite-product-logs`,
        icon: FavoriteIcon,
        component: FavouriteProductLogs,
      },
      {
        name: "Product performance",
        url: `${this.props.match.url}/product-performance`,
        icon: PollIcon,
        component: ProductPerformance,
      },
      {
        name: "Sales Performance",
        url: `${this.props.match.url}/sales-performance`,
        icon: PollIcon,
        component: SalesPerformance,
      },
      {
        name: "Wishlist",
        url: `${this.props.match.url}/wish-list`,
        icon: PollIcon,
        component: WishList,
      },
      {
        name: "Cart",
        url: `${this.props.match.url}/cart-list`,
        icon: PollIcon,
        component: Cart,
      },
      {
        name: "Funnel Chart",
        url: `${this.props.match.url}/funnel-chart`,
        icon: PollIcon,
        component: Funnel,
      },
      {
        name: "Device Installation Report",
        url: `${this.props.match.url}/device-installation-report`,
        icon: PollIcon,
        component: DeviceInstallationReport,
      },
      {
        name: "Device Session Report",
        url: `${this.props.match.url}/device-session-report`,
        icon: PollIcon,
        component: DeviceSessionReport,
      },
      {
        name: "Device Activity By Session",
        url: `${this.props.match.url}/Device-activity-by-session`,
        icon: PollIcon,
        component: DeviceActivityBySession,
      },
      {
        name: "Booking Payment Activity",
        url: `${this.props.match.url}/payment-activity`,
        icon: PollIcon,
        component: BookingPaymentActivity,
      },
      {
        name: "Seller Statistics",
        url: `${this.props.match.url}/seller-statistics`,
        icon: PollIcon,
        component: SellerStatistics,
      },
      {
        name: "Catalog Statistics",
        url: `${this.props.match.url}/catalog-statistics`,
        icon: PollIcon,
        component: CatalogStatistics,
      },
      {
        name: "HeatMap",
        url: `${this.props.match.url}/heatmap`,
        icon: PollIcon,
        component: HeatMap,
      }

    ],
  };

  render() {
    if (this.props.location.pathname === "/") {
      this.props.history.push("/dashbord/overview");
    }
    // let store_id = localStorage.getItem("store_id");
    return (
      <div className="dash-board">
        <Header
          title="shoppd - Dashboard"
          data={this.state.sidePanel}
          url={`${this.props.match.url}/overview`}
        ></Header>

        <div className="dashbord-body">
          {/* <SidePanel data={this.state.sidePanel}></SidePanel> */}

          <div className="">
            {this.state.sidePanel.map((items, index) => {
              return (
                <Route
                  key={index}
                  path={items.url}
                  name={items.name}
                  component={items.component}
                ></Route>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

// Create validations for Props, required or type etc.
LandingIndex.propTypes = {
  dispatch: PropTypes.any.isRequired,
  lang: PropTypes.any.isRequired,
};

const mapStateToProps = (state) => {
  return {
    index: state.index,
  };
};

export default connect(mapStateToProps, null)(withRouter(LandingIndex));
