//library import
import { connect } from "react-redux";
import React, { Component } from "react";
import { setIndex } from "../../../redux/actions/auth";
import ReactToPrint from "react-to-print";

// ! React table import
import CatalogStasticsListData from "./table";

import Modal from "../../../components/modal/modal";
import Pbutton from "../../../components/button/Button";
import { CSVLink } from "react-csv";

// Alter (snackbar or toster) 
import Snackbar from "../../../components/snackbar/snackbar";
import SearchBox from "../../../components/SearchBox/selectMulSearchBox.js"


// spinner loader
import ClipLoader from "react-spinners/ClipLoader";


// css file
import "./index.css";
import "./Overview.scss";
import "../Common.scss";
import "../global-font-css.css";
import "../../../static/scss/globalComponent.scss";

// Dropdown filter

import _ from "lodash";

// API Imports
import {
  fetchCatalogStatistics, fetchCatalogStatisticsStore
} from "../../../services/catalogStatestics";

// helper 
import { getCurrencySymbol, replaceBlankWithDash } from "../../../lib/helper";
import StoreIdDropDown from "../../../components/StoreIdDropdown";
import { storeIdApiCall } from "../../../services/storeId";



// Default API params

/**
 * @Author Jai
 * @Date 12 Jan, 2023
 * @Description Created a Demo dashboard with location filter, status, and date range
 */

class CatalogStatistics extends Component {
  myRef = React.createRef();
  state = {
    dataLoaded: false,
    isOpenSnackbar: false,
    snackbarMessage: "",

    storeIdValue: 0,
    storeData: [{ id: 0, name: "--No Data--" }],

    noDataText: "",
    search:"",

    // hidePagination : true,
    showSec: true,

    skip: 0,            //starting point
    limit: 10,
    rowsPerPage: 10,          //ending point
    page: 0,            //pagination page
    totalData: 0,  // total no of data

    loader: true,       //loader state


    logsData: [],           //table data
    thirdlogsData: [],      //table data
    logsColumn: [],

    fstColumnData: [],
    table_data: [],
    isOpen: false,
  };

  componentDidMount = () => {
    let width = window.innerWidth;
    this.getAllStoreId();
    this.setState({ width });
    window.addEventListener("resize", this.heightHandler);
    this.getData(
      this.state.skip,
      this.state.limit,
      this.state.storeIdValue,
      this.state.search
    );

  };

  //method to fetch data
  getData = (skip, limit,storeIdValue,search) => {
    this.setState({
      loader: true
    })
    fetchCatalogStatistics(
      skip,
      limit,
      storeIdValue || 0,
      search || ""
    )
      .then(data => {
        let firstColumnKey = Object.keys(data.data.data.categoryList[0])

        const logsColumn = firstColumnKey.map((key) => {
          return {
            Header: key,
            accessor: data => replaceBlankWithDash(data[key]),
            id: key,
            // minWidth: key === "From" || key === "To" ? 250 : 160,
          }
        })

        this.setState({
          logsColumn,
          thirdlogsData: data.data.data.categoryList,
          totalData: data.data.data.totalCount,
          dataLoaded: true,
          loader: false,
        })
      })
      .catch(error => {
        console.log("error", error.response)
        if (!this.state.startDate) {
          this.setState({
            loader: false,
            isOpenSnackbar: true,
            thirdlogsData:[],
            snackbarMessage: "Data Not Found",
          });
        } else {
          let message =
            error &&
              error.response &&
              error.response.statusText
              ? error.response.statusText
              : "Data Not Found!";
          this.setState({
            dataLoaded: false,
            loader: false,
            isOpenSnackbar: true,
            thirdlogsData:[],
            snackbarMessage: message,
          });
        }
        setTimeout(() => {
          this.setState({
            isOpenSnackbar: false,
          });
        }, 2000);
      })
  };

  dropDownSelect = (e) => {
    this.setState(
      {
        storeIdValue: e.target.value,
      });

    this.getData(
      this.state.skip,
      this.state.limit,
      e.target.value,
      this.state.search
    );
  };

  getSelectedSearch = (value) => {
    if (value['Category Name'] == "0"){
      this.getData(
      this.state.skip,
      this.state.limit,
      this.state.storeIdValue,
      ""
    )}
    else{
      this.getData(
        this.state.skip,
        this.state.limit,
        this.state.storeIdValue,
        value['Category Name'].split("(")[0].trim()
      )
  
    };}
    
  getAllStoreId = () => {
    fetchCatalogStatisticsStore()
      .then((res) => {
        
        let data = [{"id": 0, name : "All"}]
        data.push(...res.data.data)
        this.setState({
          storeData: data,
          dataLoaded: true,
        });
      })
      .catch((err) => {
        console.log("error store id", err);
        this.setState({
          dataLoaded: false,
        });
      });
  };
 

  handleRowsPerPage = (rowsPerPage) => {
    console.log("rowsPerPage ===> ", rowsPerPage)

    this.setState({
      // loader: true,
      rowsPerPage: rowsPerPage
    })

    this.getData(
      this.state.page,
      rowsPerPage,
      this.state.storeIdValue,
      this.state.search 
    );

  }
  handlePagination = (newPage) => {
    this.setState({
      loader: true,
      page: newPage,
    })
    this.getData(
      newPage * this.state.rowsPerPage,
      this.state.rowsPerPage,
      this.state.storeIdValue,
      this.state.search
    );

  }

  toggleModal = () => {
    this.setState({ modal: !this.state.modal });
  };
  render() {

    const { country, city } = this.state;
    return (
      <div className="containerDiv" ref={this.myRef}>
        <span
          className="hoverPathClass globalFontSize"
          onClick={() => this.props.history.push("overview")}
        >
          <i className="fas fa-angle-left mr-2"></i>Reports
        </span>
        <div className="title">Catalog Statistics</div>
        <div
          className="d-flex text-grey mt-2 globalFontSize"
          style={{ marginBottom: "" }}
        >
          <div className="mr-3 db_ptr">
            <ReactToPrint
              trigger={() => (
                <span>
                  <i className="fas fa-print mr-1"></i>Print
                </span>
              )}
              content={() => this.myRef.current}
            />
          </div>
          <div className="mr-3 db_ptr" onClick={this.toggleModal}>
            <i className="fas fa-download mr-1"></i>Export
          </div>
        </div>
        {/* <div className="d-flex align-items-center justify-content-between mt-4 mb-4">
        </div> */}

        <div className="d-flex align-items-center justify-content mb-2">
          {/* <div className="mt-3 mb-2">
            <DateRangePicker
              text={true}
              handleDateChange={this.selectGroupByPeriodHandler}
            />
          </div> */}
          <div className="d-flex align-items-center globalFontSize mt-3 mb-2 ml-2">
            <StoreIdDropDown
              storeName = {"Stores :"}
              storeData={this.state.storeData}
              storeID={this.state.storeIdValue}
              dropDownSelect={this.dropDownSelect}
            />
          </div>


          <div className="d-flex justify-content-end align-items-center ml-auto">
            <span className="mr-2">Search:</span>

            <div className="mr-2">
             <SearchBox 
                selectedSearch={this.getSelectedSearch}
                searchRows={this.state.thirdlogsData}
             />
            </div>
          </div>
        </div>

        <div
          className="activeBarGraph d-flex align-items-center justify-content-center"
          style={{ padding: 0, marginTop: '3rem', height: "100%", minHeight: "400px" }}
        >
          {this.state.loader ? (
            <ClipLoader color="blue" size={40} />
            // <h4 style={{ color: "grey" }}>Loading data...</h4>
          ) : this.state.dataLoaded ? (
            <div
              style={{
                // marginTop: "4rem",
                height: "100%",
                padding: "10px 10px 10px 10px",
                width: "100%",
              }}
            >

              <CatalogStasticsListData
                columnName={this.state.logsColumn}
                rows={this.state.thirdlogsData}
                totalData={this.state.totalData}
                tablerRowPerPage={this.handleRowsPerPage}
                tablePagination={this.handlePagination}
              />
            </div>
          ) : (
            <h4 style={{ color: "grey" }}>No data found</h4>
          )}
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggleModal}
          width={"35%"}
        >
          <div className="col-12 px-0">
            <div className="py-3 reportModal-header pl-3 border-bottom">
              Export your report
            </div>
            <div className="py-3 reportModal-subText pl-3 border-bottom">
              Report will be exported as a CSV (comma separated values) table.
            </div>
            <div className="py-3 col-12">
              <div className="row justify-content-end">
                <Pbutton
                  onClick={this.toggleModal}
                  className="reportModal-cancelBtn"
                >
                  Cancel
                </Pbutton>
                <CSVLink
                  onClick={() => this.toggleModal()}
                  data={this.state.thirdlogsData}
                  filename={"my-file.csv"}
                  className="reportModal-exportBtn"
                  target="_blank"
                >
                  Export
                </CSVLink>
              </div>
            </div>
          </div>
        </Modal>
        <div>
          <Snackbar
            open={this.state.isOpenSnackbar}
            message={this.state.snackbarMessage}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setIndex: (index) => dispatch(setIndex(index)),
  };
};

export default connect(null, mapDispatchToProps)(CatalogStatistics);
