export const api = window._env_.API_URL;
// export const api = "https://api.shoppd.net";
//  export const api  = "https://api.mettamuse.com";
// export const api = "https://api.dubaioutletmall.com";


// export const api/ = "https://api.emporium.ae";




// export const loginApi = "https://api.shoppd.net/v1/manager/logIn";
// export const regionApi = "https://api.shoppd.net/v1/ipLocation";
// export const resetPasswordApi = "https://api.shoppd.net/v1/manager/forgotPassword";


